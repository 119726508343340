import React, { useState, useEffect } from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import { Button, Modal } from 'react-bootstrap';
import { db } from '../firebase';
import { collection, getDocs, where, serverTimestamp, query, addDoc,doc, updateDoc, deleteDoc } from 'firebase/firestore';


function NewOrders() {
    const [orders, setorders] = useState([])
    const [loading, setloading] = useState(false)

    const deleteOrder=async(id,email)=>{

        setloading(true)
        try {
            await deleteDoc(doc(db,"Orders", id))
            console.log("order declined")

            const docRef2 = await addDoc(collection(db, "latestReq"), {
                idUser: email,
                action: `la commande ${id} refusee`,
                timeadded: serverTimestamp(),

            });
            fetchOrder()
            setloading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const accepteOrder = async (id,email) => {
        console.log(id)
        setloading(true)
        try {
            const docRef = await updateDoc(doc(db, "Orders", id), {
                accepted: "active",
                dateAccepted: serverTimestamp()

            });
            const docRef2 = await addDoc(collection(db, "latestReq"), {
                idUser: email,
                action: `la commande ${id} acceptee`,
                timeadded: serverTimestamp(),

            });
            fetchOrder();

            setTimeout(() => {
                setloading(false)
            }, 1000);

            console.log("order accepted success")
        } catch (error) {
            console.log(error.message)
        }

    }
    const fetchOrder = async () => {
        try {
            const ref = collection(db, "Orders")
            const q = query(ref, where("accepted", "==", "no"))
            const row = []
            const querySnapshot = await getDocs(q)
            querySnapshot.docs.forEach(((doc) =>
                row.push({
                    id: doc.id,
                    nameUser: doc.data().nameUser,
                    location: doc.data().location,
                    type: doc.data().type,
                    title: doc.data().title,
                    description: doc.data().description,
                    timeadded: doc.data().timeadded.toDate().toDateString(),
                    timeaddedSeconds: doc.data().timeadded,
                    accepted:<div className="d-flex"><Button className="w-100 mx-1" variant="success"
                        onClick={() =>
                            accepteOrder(doc.id,doc.data().idUser)
                            //console.log(doc.data().timeadded.seconds)
                        }>Yes</Button>
                        <Button className="w-100" variant="danger"
                        onClick={() =>
                            deleteOrder(doc.id,doc.data().idUser)
                        }>No</Button>
                        </div> ,
                })
            ))
            setorders(row.sort((a, b) => b.timeaddedSeconds.seconds - a.timeaddedSeconds.seconds))
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        fetchOrder()
    }, [])

    const data = {
        columns: [
            {
                label: 'Nom',
                field: 'nameUser',
                sort: 'disabled',
                width: 150,
            },
            {
                label: 'Titre',
                field: 'title',
                sort: 'disabled',
                width: 200,
            },
            {
                label: 'Ville',
                field: 'location',
                sort: 'disabled',
                width: 50,
            },
            {
                label: 'Type',
                field: 'type',
                sort: 'disabled',
                width: 50,
            },

            {
                label: 'Description',
                field: 'description',
                sort: 'disabled',
                width: 100,
            },
            {
                label: '',
                field: 'accepted',
                sort: 'disabled',
                width: 100,
            },
            // {
            //     label: 'Date de commande',
            //     field: 'timeadded',
            //     width: 200,
            //     sort: 'disabled',
            // },

        ],
        rows: orders
    }



    return (
        <>
            <Modal className="w-auto h-auto" show={loading}
                centered >
                < div className="loading">
                </div>
            </Modal>

            <MDBDataTableV5 hover
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                data={data}
                searchTop
                theadTextWhite
                tbodyTextWhite
                searchBottom={false}
                barReverse
                className="dataTableStyle m-3 p-3 bg-dark text-secondary rounded"
                fullPagination />
        </>
    )
}

export default NewOrders
