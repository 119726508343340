import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter , Route, Routes, Navigate} from 'react-router-dom';
import User from "./components/Login/User";
import Dashboard from './components/dashboard/Dashboard';
import Home from './components/Home/Home';
import Myorders from './components/dashboard/Myorders';
import Admin from './components/admin/Admin';
import { AuthProvider, useAuth } from './components/contexts/AuthContext';

import ListUsers from './components/admin/AllUser';
import ListReq from './components/admin/LatestReq';
import DashAdmin from './components/admin/AdminDash';


import LoginAdmin from './components/admin/LoginAdmin';
import Profil from './components/dashboard/Profil';
import AllUserOrders from './components/dashboard/AllUserOrders';
import Delivery from './components/delivery/Delivery';
import NewCommande from './components/delivery/NewCommande';
import OnDelivery from './components/delivery/OnDelivery';
import Questions from './components/Blog/Questions';
import LoginDelivery from './components/delivery/LoginDelivery';

import ListOrders from './components/admin/ListOrders';
import { BlogNews } from './components/Blog/BlogNews';
import CommentPasse from './components/Blog/CommentPasse';
import OffresFastcoursier from './components/Blog/OffresFastcoursier';
import AvantageFastcoursier from './components/Blog/AvantageFastcoursier';
import ServiceFastcoursier from './components/Blog/ServiceFastcoursier';



function App() {



  let [role, setrole] = useState()
  //const [email, setemail] = useState()

  // const isAdmin = async (email) => {
  //   if (email) {


  //     try {
  //       const docRef = doc(db, "Users", email);
  //       const docSnap = await getDoc(docRef);

  //       if (docSnap.exists()) {
  //         if (docSnap.data().role === "admin") {
  //           console.log('is admin')
  //           return ("true")
  //         } else {
  //           console.log("is not admin!");
  //           return ("false")
  //         }
  //       } else {
  //         console.log("email not found!");
  //       }
  //     } catch (error) {
  //       console.log(error.message)
  //     }
  //   } else console.log("no email added")
  // }

  const PrivateOutlet = () => {
    const { currentUser } = useAuth();
    console.log(currentUser)
    return currentUser ? <User /> : <Navigate to="/" />

    // if(currentUser)
    // {
    //   if(isRoleAdmin)
    //   {
    //     if(location.pathname==="/admin")
    //     return <Admin/>
    //     else
    //     <Navigate to="/"/>
    //   }
    //   else
    //   return <User/>
    // }
    // else
    // return <Navigate to="/"/>
    

  }

  const PrivateOutlet2 = () => {
    const {isRoleAdmin} = useAuth();
    if(isRoleAdmin) console.log(isRoleAdmin)
    return isRoleAdmin ? <Admin /> : <LoginAdmin />
  }
  const PrivateOutlet3 = () => {
    const {isRoleDelivery} = useAuth();
    if(isRoleDelivery) console.log(isRoleDelivery)
    return isRoleDelivery ? <Delivery /> : <LoginDelivery />
  }

  return (
    <>
    <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={ <Home /> } />
            <Route path="/*" element={<Navigate to="/" /> } />
            {/* <Route path="" element={<LoginAdmin />} /> */}
            {/* route of blog article */}
            <Route path="/help" element={<BlogNews />} />
            <Route path="/offres-ecommerce" element={<OffresFastcoursier />} />
            <Route path="/avantage-des-services" element={<AvantageFastcoursier />} />
            <Route path="/multiform-services" element={<ServiceFastcoursier />} />
            <Route path="/comment-se-passe-notre-livraison" element={<CommentPasse />} />
            {/* private route of user */}
            <Route path="/user" element={<PrivateOutlet />} >
              <Route path="/user/dashboard" element={<Dashboard />} />
              <Route path="/user/myorders" element={<Myorders />} />
              <Route path="/user/archive" element={<AllUserOrders />} />
              <Route path="/user/listusers" element={<ListUsers />} />
              <Route path="/user/listrequest" element={<ListReq />} />
              <Route path="/user/profil" element={<Profil />} />
            </Route>
            <Route path="/admin" element={<PrivateOutlet2 />}  >
              <Route path="/admin/dashboard" element={<DashAdmin />} />
              <Route path="/admin/listorder" element={<ListOrders />} />
              <Route path="/admin/listusers" element={<ListUsers />} />
              <Route path="/admin/listnews" element={<ListReq />} />

            </Route>
            <Route path="/delivery" element={<PrivateOutlet3 />}>
            <Route path="/delivery/newcommande" element={<NewCommande />} />
              <Route path="/delivery/ondelivery" element={<OnDelivery />} />
            </Route>
           
          </Routes>
        </AuthProvider>

    </BrowserRouter>
    </>
  )
}

export default App;
