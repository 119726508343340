import React from 'react'
import image1 from '../img/comment-passe.png'
import image2 from '../img/fastcoursierlivraison.png'
import image3 from '../img/fastcoursierservice.png'
import image4 from '../img/fastcoursieroffres.png'
import { HeaderMenu2 } from '../Header/HeaderMenu2'


export const BlogNews = () => {
    const imgs = [
        {
            img: image1,
            src: '/comment-se-passe-notre-livraison'
        },
        {
            img: image2,
            src: '/avantage-des-services'
        },
        {
            img: image3,
            src: 'multiform-services'
        },
        {
            img: image4,
            src: 'offres-ecommerce'
        }]

    const BlogCard = (props) => {
        return (
            <li className="card d-inline-block mx-3 mb-3 itemBlogNews"  style={{ width: "25rem", borderWidth: 1, margin: 8, }}

            >
                <img className="card-img-top" src={props.img} alt="Card image cap" />
                <div className="card-body">
                    <h5 className="card-title">{props.titre}</h5>
                    <p className="card-text">{props.text}</p>
                    <a href={props.src} className="btn btn-outline-primary rounded-pill ">savoir plus</a>
                </div>
            </li>
        )
    }

    return (
        <div>
            <HeaderMenu2/>
            <div className="container blogNewsItems" >
                <ul className="d-inline-block " id="slide">
                    {imgs.map((item) =>
                        <BlogCard img={item.img} src={item.src} />
                    )
                    }
                </ul>

            </div>
        </div>
    )
}
