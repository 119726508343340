import React from 'react'
import "./deliveryCss.css"
import {useAuth} from '../contexts/AuthContext'
import { useNavigate } from 'react-router';

function Bardelivery() {
    const { logout } = useAuth();
    const navigate = useNavigate()
    const logOut = async () => {
        try {
            await logout()
            navigate("/")
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="navbar bg-dark text-white justify-content-center align-content-center menuBarDelivery">
            <ul className="d-flex list-unstyled BarDelivery">
                <li className="p-2 mx-3"><a href="/delivery/newcommande" className="text-white "> Nouveau commandes</a></li>
                <li className="p-2"><a href="/delivery/ondelivery" className="text-white">En cours</a></li>
                <li className="p-2 signOutDelivery"><i class="fa fa-sign-out-alt" aria-hidden="true" onClick={logOut} ></i></li>
            </ul>
        </div>
    )
}

export default Bardelivery
