import React,{useState,useEffect} from 'react'
import '../dashboard/dashboard.css'
import { db } from '../firebase';
import { collection, getDocs, limit, query, where,orderBy } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext'
import ListOrders from './ListOrders';
import NewOrders from './NewOrders';


function AdminDash() {
    const [latestR, setlatestR] = useState([])
    const [totalOrders, settotalOrders] = useState()
    const [ordersAccepted, setordersAccepted] = useState()
    const [onDeliveryOrders, setonDeliveryOrders] = useState()

    const { currentUser } = useAuth();
   
    const fetchorders = async () => {
        try {
            const ref = collection(db, "Orders")
            const q = query(ref)
            const q2 = query(ref,where("onDelivery","==","active"),where("delivered","==","no"))
            const q3 = query(ref,where("delivered","==","active"))
            const querySnapshot = await getDocs(q)
            const querySnapshot2 = await getDocs(q2)
            const querySnapshot3 = await getDocs(q3)
            settotalOrders(querySnapshot.docs.length)
            setordersAccepted(querySnapshot2.docs.length)
            setonDeliveryOrders(querySnapshot3.docs.length)
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        fetchorders();
    }, [])

    function makeAdmin(){
        // admin.auth().getUserByEmail("testtes@gmail.com").then((user)=>{
        //     admin.auth().setCustomUserClaims(user.uid,{admin:true}).then(()=>
        //     {console.log(user.uid)})
        // })
    }

    return (
        <div style={{}}>

            <div className="state d-flex justify-content-lg-center align-text-center ">
                <ul className="list-group list-group-horizontal justify-content-center text-center m-3">
                    <li className="totalorder list-group-item bg-dark text-white  m-lg-3">
                        <label className="p-2">Total commande</label>
                        <h3>{totalOrders}</h3>
                    </li>
                    <li className="deliveredorder list-group-item bg-dark text-white m-lg-3">
                        <label className="p-2">Commande livrée</label>
                        <h3>{onDeliveryOrders}</h3>
                    </li>
                    <li className="ondeliveryorder list-group-item  bg-dark text-white m-lg-3">
                        <label className="p-2">En cours</label>
                        <h3>{ordersAccepted}</h3>
                    </li>
                </ul>
            </div>
            <div className="latest ">
                <label className=" bg-dark text-white w-100 text-center rounded h4"><b>Commandes</b></label>
                <ul className="list-group w-100 ">
                    
                    <NewOrders/>
                </ul>
            </div>
        </div>
    )
}

export default AdminDash
