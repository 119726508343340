import React from 'react'
import "./sidebar.css"
import logo from "../img/logo.png"
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import avatar from '../img/avatar.png'

function SideBarDash() {

    let navigate = useNavigate();
    const { currentUser, logout } = useAuth();
    const logOut = async () => {
        try {
            await logout()
            navigate("/")
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>

            <div className="sidebarDash d-flex flex-column vh-100 flex-shrink-0 p-3 text-white bg-dark" 
                > <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none logoImage">
                    </a>
                <hr />
                <ul className="nav nav-pills flex-column mb-auto ">

                    <li>
                        <a href="/user/dashboard" className="nav-link text-white">
                            <i class="fa fa-tachometer-alt"></i>
                            <span className="ms-2 textSideBar">Dashboard</span> </a> </li>
                    <li>
                        <a href="/user/myorders" className="nav-link text-white">
                            <i className="fa fa-tasks"></i>
                            <span className="ms-2">Commandes</span> </a> </li>
                    {/* <li>
                        <a href="/user/neworder" className="nav-link text-white">
                            <i className="fa fa-archive"></i>
                            <span className="ms-2">Archive</span> </a> </li> */}
                           <li>
                            <a href="/user/archive" className="nav-link text-white">
                        <i class="fa fa-archive"></i>
                            <span className="ms-2">Archive</span> </a> </li>
                            
                </ul>
                <div className="dropdown">
                    <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false" > 
                    <img src={avatar} alt="" width="32" height="32" className="rounded-circle me-2" /> {currentUser.displayName} </a>
                    <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                        <li><a className="dropdown-item" href="/user/profil">Profile</a></li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li><a className="dropdown-item" href="#" onClick={logOut}>Deconnecter</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SideBarDash
