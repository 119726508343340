import React from 'react'
import Typewriter from 'typewriter-effect'
import "./Accueil.css"
import casaimage from '../img/casaimage.jpeg'
function Accueil() {
    return (
        <div className="accueil" id="Accueil">
            <img className="imageAccueil" src={casaimage} alt=""  />
            <div className='typewriter'>
                <h1 className="logoName">Fast coursier</h1>
                <Typewriter
                    options={{ loop: true }}
                    onInit={(typewriter) =>
                        typewriter.typeString("Votre service de livraison, votre coursier express").start()
                            .pauseFor(2000).deleteAll()
                            .typeString("Service de livraison adapte").pauseFor(2000).deleteAll()
                            .typeString("Commandez votre coursier en un clic").pauseFor(2000).deleteAll().start()
                    }
                />

                {/* <a href="#Contactus" type="button" className="btn btn-danger rounded-pill">Contactez nous</a> */}
            </div>
        </div>
    )
}

export default Accueil
