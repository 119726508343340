import React, { useState, useRef } from 'react'
import './Login.css'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import SignUp from './SignUp';
import { useAuth } from '../contexts/AuthContext';
import { Alert } from 'react-bootstrap';
import logo from '../img/logo.png'


function Login() {

    let navigate = useNavigate();

    const loginRef = useRef();
    const EmailRef = useRef();
    const passRef = useRef();

    const [error, seterror] = useState("")
    const [messages, setmessages] = useState("")
    const [showmodals, setshowmodals] = useState(false)
    const [showForgotPass, setshowForgotPass] = useState(false)
    const [loading, setloading] = useState(false)

    const { login,resetPassword } = useAuth();

    async function loginSub(e) {
        e.preventDefault();
        try {
            setloading(true)
            seterror("")
            await login(loginRef.current.value, passRef.current.value)
            
            navigate("/user/dashboard")
        } catch (error) {
            setloading(false)
            seterror("check your email and password")
        }
    }
    async function resetPasswords(e){
        e.preventDefault();

        try {
            await resetPassword(EmailRef.current.value)
            setmessages("check your email box")
        } catch  {
            setmessages("email incorrect")
        }
    }

    return (
        <>
            <div className="container fadeInDown wrapper">
                <div className="loginItem" id="formContent">
                    <div>
                        <img src={logo} id="icon" alt="User Icon" />
                    </div>
                    <form onSubmit={loginSub}>
                        <input type="text" id="login" className="fadeIn second" name="login" ref={loginRef} placeholder="Email" required/>

                        <input type="password" id="password" className="fadeIn third" name="password" ref={passRef} placeholder="Mot de passe" required/>

                        <button type="submit" className="fadeIn fourth btnSub" >Se connecter</button>
                        <div className="">
                        {loading && 
                        <div class="spinner-border text-success" role="status">
                            <span class="sr-only" >Loading...</span>
                        </div>}
                    </div>
                    </form>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <a className="underlineHover" onClick={() => setshowmodals(true)}>Inscription</a>
                </div>
                <div id="formFooter">
                    <a className="underlineHover" onClick={()=>setshowForgotPass(true)}>Mot de passe oublie</a>
                </div>
                <div className="modalsignup">
                    <Modal show={showmodals} onHide={() => setshowmodals(false)}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Inscription</Modal.Title>
                        </Modal.Header>
                        <SignUp />
                    </Modal>

                    <Modal show={showForgotPass} onHide={() => setshowForgotPass(false)}
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Mot de passe oublie</Modal.Title>
                        </Modal.Header>
                        <div className="container-fluid wrapper" id="formContent">
                                <form onSubmit={resetPasswords }> 
                                    <input type="text" id="login" className="fadeIn second" name="login" ref={EmailRef} placeholder="Email" />
                                    <button className="fadeIn fourth btnSub"  >changer le mot de passe</button>
                                    {messages && <Alert>{messages}</Alert>}
                                </form>
                        </div>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default Login
