import React from 'react'
import { HeaderMenu } from '../Header/HeaderMenu';
import Accueil from '../Accueil/Accueil';
import Blog from '../Blog/Blog';
import AboutUs from '../AboutUs/AboutUs';
import Services from '../Services/Services2';
import { BlogNews } from '../Blog/BlogNews';

function Home() {
  return (
    <React.Fragment>
      <div className="">
      <HeaderMenu />
      <Accueil />
      <AboutUs />
      <Services />
      <Blog />
      </div>
      <footer style={{ display: 'flex', width: '100%' }} className="container text-white" >
        <div className="d-block footerContact">
          <h4 className="h4"><strong>
            Contactez nous
          </strong>
          </h4>
          <h4>
            Adresse : Boulevard la résistance Casablanca
          </h4>
          <h4>
            Tél : 0696551168
          </h4>
          <h4>
            Email : contact@fastcoursier.ma
          </h4>
          <h4>
            Horaires : 24h/7j
          </h4>
        </div>
        <div className="d-flex footerSocial">
          <a href="https://www.facebook.com/Fast-Coursier-1270077659820294/" className="iconFB">
            <i className="fab fa-facebook fa-2x  p-2 text-white" id="iconFB" ></i>
          </a>
          <a href="http://instagram.com/fastcoursier">

            <i className="fab fa-instagram fa-2x  p-2 text-white" id="iconINSTA"></i>
          </a>
          <a href="https://www.linkedin.com/in/fast-coursier/">
            <i className="fab fa-linkedin fa-2x  p-2 text-white" id="iconLINK"></i>
          </a>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Home
