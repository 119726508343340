import React from 'react'
import { BlogNews } from '../Blog/BlogNews'
import './Services.css'

function Services2() {

    const items = [{
        id: 'Professionnels',
        content: `Nous sommes en capacité de répondre à vos besoins de transport et livraison de vos courses urgentes, de plis, colis et documents administratifs ainsi que la distribution des flyers.\n\nNous vous garantissons la qualité, en toute sécurité 7j/7 `,
        icon: 'fa fa-users fa-5x'
    },
    {
        id: 'Particuliers',
        content: 'Notre équipe jeune et dynamique, met à votre disposition tout son savoir faire pour répondre à vos besoins de livraison de vos courses, plis, colis et documents administratifs. \n\n Nous vous garantissons la qualité, en toute sécurité 7j/7',
        icon: 'fa fa-user fa-5x'
    },
    {
        id: 'Location de coursier',
        content: 'Nous vous proposons la solution de transport sur mesure qui vous permet d’avoir un coursier véhiculé à disposition pour la durée de votre choix. \n\nContactez-nous pour plus de détails.'
        , icon: 'fa fa-globe fa-5x'
    },
    ]
    const Services = (props) => {
        return <><li className="itemstyle">
            <i className={props.icon} style={{color:"#31373C"}} ></i>
            <p style={{ fontWeight: 'bold' }}>{props.id}</p>{props.value}</li></>
    }

    return (
        <div className="container services" id="Services">
            <h2 className="titleServices">Nos services</h2>
            <ul className="itemslist">
                {items.map((item) => <Services className="servicesName" key={item.id} id={item.id} value={item.content} icon={item.icon} />)}
            </ul>
            <div className="container text-white" style={{textAlign:'center'}}>
                <p><strong>
                Fast Coursier : la livraison en un clin d’œil</strong> </p>
                <p>
                    Vous êtes un <strong>particulier</strong> ou un <strong>professionnel </strong>qui voudrait se faire livrer, ou envoyer un coli à quelqu’un ?
                </p>
                <p>Ne cherchez plus loin. Fast Coursier est disponible pour vous.</p>
                <a className="p-2 bg-success bg-gradient text-white "  href="/help">Savoir plus </a>
            </div>
                
        </div>
    )
}

export default Services2
