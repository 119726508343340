import React, { useRef, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Alert, Form } from 'react-bootstrap'
import logoImg from '../img/logo.png'
import { db } from '../firebase';
import { collection, addDoc, serverTimestamp, doc, setDoc } from 'firebase/firestore';

function SignUp() {
    const [error, seterror] = useState()
    const [message, setmessage] = useState()
    const [loading, setloading] = useState(false)
    const loginRef = useRef()
    const NumRef = useRef()
    const passRef = useRef()
    const confpassRef = useRef()

    const NameRef = useRef()
    const SurnamRef = useRef()

    const { signup } = useAuth()

    async function handleSubmit(e) {
        e.preventDefault()

        if (passRef.current.value !== confpassRef.current.value)
            return seterror('check your password')

        try {
            seterror("")
            setloading(true)

            const userSign = await signup(loginRef.current.value, passRef.current.value)
            userSign.user.updateProfile({
                displayName: `${NameRef.current.value} ${SurnamRef.current.value}`
            })

            const docRef = await setDoc(doc(db, "Users", loginRef.current.value), {
                idUser: loginRef.current.value,
                Number: NumRef.current.value,
                Name: NameRef.current.value,
                Surname: SurnamRef.current.value,
                role: "user"
            })

            const docRef2 = await addDoc(collection(db, "latestReq"), {
                idUser: loginRef.current.value,
                action: `New accout`,
                timeadded: serverTimestamp()
            })
            setloading(false)
            setmessage('inscription terminer')

        } catch (error) {
            if (error.code == "auth/email-already-in-use")
                seterror("Adresse email deja utilisé")
            else
                seterror("inscription erronée")
            setloading(false)
            setmessage('')
        }
    }

    return (
        <div>
            <div className="container fadeInDown formInputStyle">
                <div className="loginItem" id="formContent">
                    <div>
                    </div>
                    <Form onSubmit={handleSubmit} className="m-lg-2">
                        <div className="form-row nameStyle">
                            <div className="form-group col-md-6">
                                <input type="text" className="fadeIn second" ref={NameRef} placeholder="Nom" />
                            </div>
                            <div className="form-group col-md-6">
                                <input type="text" className="fadeIn second" ref={SurnamRef} placeholder="Prenom" />
                            </div>
                        </div>
                        <div className="">
                            <input type="text" id="login" className="fadeIn second" name="login" ref={loginRef} placeholder="Email" required />
                            <input type="password" id="password" className="fadeIn third" name="password" ref={passRef} placeholder="mot de passe" required />
                            <input type="password" id="password" className="fadeIn fourth" name="confirmPass" ref={confpassRef} placeholder="confirme le mot de passe" required />
                            <input type="tel" id="Numero" className="fadeIn fourth" name="Numero" ref={NumRef} placeholder="Telephone" pattern="[0-9]{10}" required />


                            <button disabled={loading} type="submit" className="fadeIn fourth btnSub mt-4 mb-1" >S'inscrire</button>
                        </div>
                        {error && <Alert variant="danger">{error} </Alert>}
                        {message && <Alert>{message} </Alert>}
                    </Form>
                    <div className="p-3">
                        {loading &&
                            <div className="spinner-border text-success" role="status">
                                <span className="sr-only" >Loading...</span>
                            </div>}
                    </div>

                </div>

            </div>
        </div >
    )
}


export default SignUp
