import React from 'react'
import { useState, useEffect, useRef } from 'react'

import { MDBDataTableV5 } from 'mdbreact';
import { db } from '../firebase';
import { collection, getDocs, setDoc, doc } from 'firebase/firestore';
import { Button, Modal, Form, Alert } from 'react-bootstrap';


function AllUser() {
    const [users, setusers] = useState([])
    const [update, setupdate] = useState(false)
    const [docId, setdocId] = useState()
    const [iduser, setiduser] = useState()
    const [name, setname] = useState()
    const [surname, setsurname] = useState()
    const [number, setnumber] = useState()
    const [adresse, setadresse] = useState()
    const [loading, setloading] = useState(false)
    const [role, setrole] = useState()
    const [errormsg, seterror] = useState()
    const roleRef = useRef()

    function deleteItem(id) {
        alert(id)
    }
    const onUpdate = (id, doc) => {
        setdocId(id)
        setname(doc.Name)
        setsurname(doc.Surname)
        setnumber(doc.Number)
        setrole(doc.role)
        setadresse(doc.Adresse)
        setupdate(true)
    }
    const updateItem = async () => {

        if (role === "Role") {
            seterror("selectione un role")

        } else {
            setloading(true)
            try {
                const ref = doc(db, "Users", docId)
                const docRef = await setDoc(ref, {
                    Name: name,
                    Surname: surname,
                    Number: number,
                    Adresse: adresse,
                    role: role,
                });
                setupdate(false)
                fetchUser()
                setloading(false)
            } catch (error) {
                console.log(error)
            }
        }
    }

    const fetchUser = async () => {
        try {
            const ref = collection(db, "Users")
            const row = []
            const querySnapshot = await getDocs(ref)
            querySnapshot.docs.map(((doc) =>
                row.push({
                    id: doc.id,
                    nom: doc.data().Name,
                    prenom: doc.data().Surname,
                    numero: doc.data().Number,
                    adresse: doc.data().Adresse,
                    edit: <>
                        {/* <Button className="btn btn-info"
                        onClick={() => onUpdate(doc.data())}>
                        <i className="fa fa-edit "></i>
                    </Button> */}
                        <Button onClick={() => onUpdate(doc.id, doc.data())} className="mx-1 btn btn-warning" >
                            <i className="fa fa-edit "></i>
                        </Button></>
                })
            ))
            setusers(row)



        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        fetchUser()
    }, [])
    const data = {
        columns: [

            {
                label: 'id user',
                field: 'id',
                width: 150,
            },
            {
                label: 'Nom',
                field: 'nom',
                width: 50,
            },
            {
                label: 'Prenom',
                field: 'prenom',
                width: 50,
            },
            {
                label: 'Numero',
                field: 'numero',
                sort: 'disabled',
                width: 100,
            },
            {
                label: 'adresse',
                field: 'adresse',
                sort: 'disabled',
                width: 100,
            },
            {
                label: '',
                field: 'edit',
                sort: 'disabled',
                width: 100,
            },

        ],
        rows: users
    }
    return (
        <div>
            <MDBDataTableV5 hover
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                data={data}
                searchTop
                searchBottom={false}
                barReverse
                className="dataTableStyle"
                fullPagination />

            <Modal show={update} onHide={() => { setupdate(false); seterror("") }}
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                <Form className="container m-auto w-100">
                    <div className="mb-3">
                    <label for="name" className="form-label">Nom :</label>
                    <input type="text" id="name" className="form-control w-100" onChange={(e) => setname(e.target.value)} value={name} required />
                    </div>
                    <div className="mb-3">
                    
                    <label for="surname"  className="form-label">Prenom :</label>
                    <input type="text" className="form-control w-100" id="surname" onChange={(e) => setsurname(e.target.value)} value={surname} required />
                    </div>
                  
                  <div className="mb-3">
                      <label for="tel" className="form-label">Telephone :</label>
                    <input type="text" className="form-control w-100" id="tel" onChange={(e) => setnumber(e.target.value)} value={number} required />
                  </div>
                  
                  <div className="mb-3">
                      <label for="adresse" className="form-label">Adresse :</label>
                    <input type="text" className="form-control w-100" id="adresse" onChange={(e) => setadresse(e.target.value)} value={adresse} required />
                  </div>
                  <div className="mb-3">
                  <label className="form-label">Role :</label>
                    <select className="form-select mb-3 w-100 p-2 text-center" aria-label="Default select example" onChange={(e) => setrole(e.target.value)} ref={roleRef} value={role} >
                        <option selected>Role</option>
                        <option value="admin">admin</option>
                        <option value="delivery">delivery</option>
                        <option value="user">user</option>
                    </select>
                  </div>
                    {errormsg && <Alert className="text-center" variant="danger">{errormsg}</Alert>}
                    <Button className="w-100 mb-2" onClick={() => updateItem()} >Modifier</Button>
                </Form>

            </Modal>

            <Modal className="w-auto h-auto" show={loading} centered >
                < div className="loading">
                </div>
            </Modal>
        </div>
    )
}

export default AllUser
