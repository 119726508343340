import React, { useEffect, useState } from 'react'
import { MDBDataTableV5 } from 'mdbreact';
import { Button, Modal } from 'react-bootstrap';
import { db } from '../firebase';
import { collection, getDocs, where, serverTimestamp, query, setDoc, addDoc, doc, updateDoc } from 'firebase/firestore';
import './deliveryCss.css'

function NewCommande() {
    const [orders, setorders] = useState([])
    const [loading, setloading] = useState(false)
    const accepteOrder = async (id, data) => {

        setloading(true)
        try {
            const ref = doc(db, "Orders", id)
            const docRef = await updateDoc(ref, {
           
                accepted: "active",
                onDelivery: "active",
                delivered: "no",
                dateAccepted: serverTimestamp()

            });

            const docRef2 = await addDoc(collection(db, "latestReq"), {
                idUser: data.idUser,
                action: `la commande ${id} en cours`,
                timeadded: serverTimestamp(),

            });
            
            fetchOrder()
            const timer = setTimeout(() => {
                setloading(false)
              }, 1000);
        } catch (error) {
            console.log(error.message)
        }

    }
    const fetchOrder = async () => {
        try {
            const ref = collection(db, "Orders")
            const q = query(ref, where("accepted", "==", "no"))
            const row = []
            const querySnapshot = await getDocs(q)
            querySnapshot.docs.forEach(((doc) =>
                row.push({
                    id: doc.id,
                    idUser: doc.data().idUser,
                    location: doc.data().location,
                    type: doc.data().type,
                    nameUser:doc.data().nameUser,
                    description: doc.data().description,
                    title: doc.data().title,
                    timeadded: doc.data().timeadded.toDate().toDateString(),
                    timeaddedSeconds: doc.data().timeadded,
                    accepted: <Button className="w-100 " variant="success"
                        onClick={() =>
                            accepteOrder(doc.id, doc.data())
                            //console.log(doc.data().timeadded.seconds)
                        }><i class="fa fa-check" aria-hidden="true"></i></Button>,
                })
            ))
            setorders(row.sort((a, b) => b.timeaddedSeconds.seconds - a.timeaddedSeconds.seconds))



        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        fetchOrder()
    }, [])


    const data = {
        columns: [
            {
                label: 'Nom',
                field: 'nameUser',
                width: 150,
            },
            {
                label: 'Titre',
                field: 'title',
                width: 150,
            },
            {
                label: 'Ville',
                field: 'location',
                width: 200,
            },
            {
                label: 'Type',
                field: 'type',
                width: 50,
            },

            {
                label: 'Description',
                field: 'description',
                width: 50,
            },
            {
                label: '',
                field: 'accepted',
                sort: 'disabled',
                width: 100,
            },
            // {
            //     label: 'Date de commande',
            //     field: 'timeadded',
            //     width: 200,
            //     sort: 'disabled',
            // },

        ],
        rows: orders
    }
    return (
        <div>
            
            <Modal className="w-auto h-auto" show={loading} 
                        centered >
               < div className="loading">
                </div>
            </Modal>
            
                
            
            <MDBDataTableV5 hover
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                data={data}
                searchBottom
                barReverse
                className="DeliveryTable m-lg-4 rounded"
                fullPagination />
        </div>
    )
}

export default NewCommande
