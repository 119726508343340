import React, { useState, useEffect } from 'react';
import { MDBDataTableV5 } from 'mdbreact';
import { db } from '../firebase';
import { collection, getDocs, where, query } from 'firebase/firestore';

export default function ListOrders() {

    const [orders, setorders] = useState([])


    const fetchOrder = async () => {
        try {
            const ref = collection(db, "Orders")
            const q = query(ref, where("accepted", "==", "active"))
            const row = []
            const querySnapshot = await getDocs(q)
            querySnapshot.docs.map(((doc) =>
                row.push({
                    id: doc.id,
                    nameUser: doc.data().nameUser,
                    location: doc.data().location,
                    type: doc.data().type,
                    title: doc.data().title,
                    description: doc.data().description,
                    timeadded: doc.data().timeadded.toDate().toDateString(),
                    etat: doc.data().delivered == "active" ? <h6 className="text-secondary">Livree</h6> : <h6 className="text-success">En cours</h6>
                })
            ))
            setorders(row)



        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        fetchOrder()
    }, [])

    const data = {
        columns: [
            {
                label: 'Nom',
                field: 'nameUser',
                sort: 'disabled',
                width: 150,
            },
            {
                label: 'Titre',
                field: 'title',
                sort: 'disabled',
                width: 200,
            },
            {
                label: 'Ville',
                field: 'location',
                sort: 'disabled',
                width: 50,
            },
            {
                label: 'Type',
                field: 'type',
                sort: 'disabled',
                width: 50,
            },

            {
                label: 'Description',
                field: 'description',
                sort: 'disabled',
                width: 100,
            },
            {
                label: 'Etat',
                field: 'etat',
                width: 200,
                sort: 'disabled',
            },

        ],
        rows: orders
    }



    return (
        <div className="container pt-5">
            <MDBDataTableV5 hover
            entriesOptions={[5, 20, 25]}
            entries={5}
            pagesAmount={4}
            data={data}
            searchTop
            theadTextWhite
            tbodyTextWhite
            searchBottom={false}
            barReverse
            className="dataTableStyle bg-dark text-secondary rounded"
            fullPagination />
        </div>
        

    )

}