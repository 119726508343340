import React,{useRef,useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Modal } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import './LoginAdmin.css'
import {useAuth} from '../contexts/AuthContext'
import logo from '../img/logo.png'

function LoginAdmin() {

    const [error, seterror] = useState()
    const [loading, setloading] = useState(false)
    let navigate=useNavigate()
    const loginRef = useRef();
    const passRef = useRef();
    const {login}=useAuth()

    async function loginSub(e){
        e.preventDefault();
        try {
            setloading(true)
            seterror("")
            await login(loginRef.current.value, passRef.current.value)
            navigate("/admin")
        } catch (error) {
            setloading(false)
            seterror("check your email and password")
        }

    }
    
    return (
        <div className="container fadeInDown LoginAdminStyle ">
                  <div className="loginItem" id="formContent">
                    <div>
                        <img src={logo} id="icon" alt="User Icon" />
                    </div>
                    <form onSubmit={loginSub} className="m-3">
                        <input type="text" id="login" className="fadeIn second" name="login" ref={loginRef} placeholder="Admin" required/>

                        <input type="password" id="password" className="fadeIn third" name="password" ref={passRef} placeholder="Password" required/>

                        <button type="submit" className="fadeIn fourth btnSub"  >Log in</button>
                    </form>
                    {loading && 
                        <div class="spinner-border text-success" role="status">
                            <span class="sr-only" >Loading...</span>
                        </div>}
                    {error && <Alert variant="danger">{error}</Alert>}
                    
                </div>
        </div>
    )
}

export default LoginAdmin
