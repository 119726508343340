
import React, { useRef, useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';

function Profil() {
    const [loading, setloading] = useState(false)
    const [message, setmessage] = useState()
    const [userInfo, setuserInfo] = useState([])
            const [Adresse, setAdresse] = useState()
            const [Name, setName] = useState()
            const [Surname, setSurname] = useState()
            const [Num, setNum] = useState()

    const nameRef = useRef()
    const surnameRef = useRef()
    const adresseRef = useRef()
    const numRef = useRef()

    const passwordRef = useRef()
    const newpassRef = useRef()
    const { currentUser, updatePassword, logout } = useAuth();

    const updatePass = async () => {
        setloading(true)
        await updatePassword(passwordRef.current.value, newpassRef.current.value)

        const timer = setTimeout(() => {
            setloading(false)
        }, 1000);

        console.log("password changed")
        await logout()
    }

    const updateProfil = async () => {
        console.log(currentUser.email)
        setloading(true)
        try {
            const ref = doc(db, "Users", currentUser.email)
            const docRef = await updateDoc(ref, {
                Number: Num,
                Name: Name,
                Surname: Surname,
                Adresse: Adresse,

            });

            currentUser.updateProfile({
                displayName: `${Name} ${Surname}`
            });

            const timer = setTimeout(() => {
                setloading(false)
            }, 1000);

            setmessage("Modification terminer")
        }
        catch (error) {
            console.log(error.message)
        }
    }

    const fetchProfil = async () => {

        setloading(true)
        try {
            const ref = doc(db, "Users", currentUser.email)
            const snapshot = await getDoc(ref)
            if (snapshot.exists) {
                setName(snapshot.data().Name)
                setSurname(snapshot.data().Surname)
                setAdresse(snapshot.data().Adresse)
                setNum(snapshot.data().Number)
            }
            const timer = setTimeout(() => {
                setloading(false)
            }, 1000);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchProfil();
        console.log(userInfo)
    }, [])


    return (
        <div>
            <Modal className="w-auto h-auto d-flex justify-content-center align-content-center" show={loading}
                centered >
                <div className="loading">
                </div>
            </Modal>
            <div className="container rounded bg-white mt-5 mb-5">

                <div className="row">

                    <div className="col-md-5 border-right">
                        <div className="p-3 py-5">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h4 className="text-right">Profile</h4>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6"><label className="labels">Nom</label><input type="text" className="form-control" placeholder="Nom"  value={Name} onChange={(e) => setName(e.target.value)}/></div>
                                <div className="col-md-6"><label className="labels">Prenom</label><input type="text" className="form-control" placeholder="Prenom" onChange={(e)=>setSurname(e.target.value)} value={Surname} /></div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <label className="labels">Numero de telephone</label>
                                    <input type="text" className="form-control" placeholder="enter phone number" onChange={(e)=>setNum(e.target.value)} value={Num} /></div>
                                <div className="col-md-12"><label className="labels">Addresse</label><input type="text" className="form-control" placeholder="enter address" onChange={(e)=>setAdresse(e.target.value)} value={Adresse} /></div>
                            </div>

                            <div className="mt-5 text-center">
                                {message && <div className="text-success">{message}</div>}
                                <button className="btn btn-primary profile-button" type="button" onClick={updateProfil}>Enregister</button></div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="p-3 py-5">
                            <div className="d-flex justify-content-between align-items-center experience"><h5>Changer le mot de passe</h5></div><br />
                            <div className="col-md-12"><label className="labels">Ancien mot de passe</label>
                                <input type="text" className="form-control" placeholder="Ancien mot de passe" ref={passwordRef} />
                            </div> <br />
                            <div className="col-md-12"><label className="labels">Nouveau mot de passe</label>
                                <input type="text" className="form-control" placeholder="Nouveau mot de passe" ref={newpassRef} />
                                <div className="m-4"><button className="btn btn-primary mx-4" type="button" onClick={updatePass}>Changer</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profil
