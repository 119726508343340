
import firebase from "firebase/compat/app";

import { initializeFirestore} from 'firebase/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/firestore'

const app = firebase.initializeApp( {
  apiKey: "AIzaSyBXhMl8G1FDKYEj1v8mwK5IeJStetb1as8",
  authDomain: "fastcoursier-c01e6.firebaseapp.com",
  projectId: "fastcoursier-c01e6",
  storageBucket: "fastcoursier-c01e6.appspot.com",
  messagingSenderId: "404311744001",
  appId: "1:404311744001:web:46feefad7db5956fbbaa00",
  measurementId: "G-FK4EYRRJ8T"
})  
export const auth =app.auth();
const firestore = initializeFirestore(app, {
  useFetchStreams: false,
});
export const db =firestore
export default app  
