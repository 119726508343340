import React, { useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Modal } from 'react-bootstrap';
import logo from '../img/logo.png';
import './HeaderMenu.css'
import Login from '../Login/Login';
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'


export const HeaderMenu = () => {

    const [navbar, setnavbar] = useState(false)

    const [showmodal, setshowmodal] = useState(false)
    let navigate = useNavigate();
    const { currentUser } = useAuth();
    
    const seconnecter = () => {
        currentUser ? navigate("/user/dashboard") : setshowmodal(true)
    }

    const changeBg =()=>{
        if(window.scrollY>=100){
            setnavbar(true)
        }
        else setnavbar(false)
    }
    window.addEventListener('scroll',changeBg)

    return (
        <>
            <Navbar variant="dark" id="navbar"
                sticky="top" expand="lg" className={navbar ? 'Navbar bg-dark' :'Navbar bg-transparent'} collapseOnSelect>
                <Navbar.Brand>
                    <img src={logo} width="200" alt='' className="imgLogo" />
                </Navbar.Brand>

                <Navbar.Toggle className="coloring" style={{fontSize:"2vw"}} />
                <Navbar.Collapse className="NavbarItems">
                    <Nav className="NavbarItems">

                        <Nav.Link className="itemLink" href="#Accueil"><h4 className="itemText text-white">Accueil</h4></Nav.Link>
                        <Nav.Link className="itemLink" href="#AboutUs"><h4 className="itemText text-white">Qui sommes nous</h4></Nav.Link>
                        <Nav.Link className="itemLink" href="#Services"><h4 className="itemText text-white">Nos services</h4></Nav.Link>
                        {/* <NavDropdown className="itemLink" title="Nos-services">
                            <NavDropdown.Item className="itemLink" href="#Nos-services/Professionels"><h4 className="itemText">Professionels</h4></NavDropdown.Item>
                            <NavDropdown.Item className="itemLink" href="#Nos-services/Particuliers"><h4 className="itemText">Particuliers</h4></NavDropdown.Item>
                            <NavDropdown.Item className="itemLink" href="#Nos-services/Location-de-coursier"><h4 className="itemText">Location de coursier</h4></NavDropdown.Item>
                            <NavDropdown.Divider />
                        </NavDropdown> */}
                        <Nav.Link className="itemLink" href="#Contactus"><h4 className="itemText text-white">Contactez nous</h4></Nav.Link>
                        <Nav.Link className='ms-4 me-4 px-3 bg-success bg-gradient text-white rounded-pill '
                            onClick={() => seconnecter()} >
                            Se connecter
                        </Nav.Link>

                    </Nav>
                </Navbar.Collapse>

            </Navbar>
            <div className="modallogin">
                <Modal show={showmodal} onHide={() => setshowmodal(false)}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Se connecter</Modal.Title>
                    </Modal.Header>
                    <Login />
                </Modal>
            </div>

        </>
    )
}


