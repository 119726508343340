import React, { useEffect, useState } from 'react'
import { Outlet,useNavigate,useLocation } from 'react-router-dom'
import Bardelivery from "./Bardelivery"


function Delivery() {
    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname=="/delivery") navigate("/delivery/newcommande")
        if (location.pathname=="/delivery/") navigate("/delivery/newcommande")    
    }, [])

    return (
        <div>
            <Bardelivery />
            <Outlet />
        </div>
    )
}

export default Delivery
