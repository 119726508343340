import React, { useRef } from 'react'
import "./Blog.css"
import emailjs from '@emailjs/browser';

function Blog() {
	const form = useRef();
	const [loading, setloading] = React.useState(false)

	const sendEmail = (e) => {
		e.preventDefault();
		setloading(true)
		emailjs.sendForm('service_9iyy8z5', 'template_6zaisgp', form.current, 'vJ0hgBv3w1LfQz2eE')
			.then((result) => {
				console.log(result.text);
			}, (error) => {
				console.log(error.text);
			});

			setTimeout(() => {
				setloading(false)
			}, 3000);
		form.current.reset()
	};

	return (
		<div className="container blog" id="Contactus">
			<form ref={form} onSubmit={sendEmail}>
				<div className="contact-box">
					<div className="left"></div>
					<div className="right">
						<h2>Contactez nous</h2>
						<input type="text" className="field" placeholder="Nom et Prenom" name="name" required />
						<input type="email" className="field" placeholder="Email" name="email" required />
						<input type="text" className="field" placeholder="Numero" name="number" />
						<textarea placeholder="Message" className="field" name="message" required></textarea>
						<button className="btnSend" type="submit">{loading ?
							<div class="spinner-border d-flex text-center " role="status">
								<span class="sr-only">Loading...</span>
							</div> : "Envoyer"
						}</button>
						
					</div>

				</div>
			</form>
		</div>
	)
}

export default Blog
