import React from 'react'
import { useState, useEffect } from 'react'
import { MDBDataTableV5 } from 'mdbreact';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

function LatestReq() {

    const [latestR, setlatestR] = useState([])
    
    const fetchReq = async () => {
        try {
            const row = []
            const ref = collection(db, "latestReq")
            const querySnapshot = await getDocs(ref)
            querySnapshot.docs.map(((doc) =>
                row.push({
                    id: doc.id,
                    idUser: doc.data().idUser,
                    action:doc.data().action,
                    timeadded:doc.data().timeadded.toDate().toDateString(),
                    timeaddedSeconds:doc.data().timeadded,
                   
                })
                
            ))
            setlatestR(row.sort((a,b)=> b.timeaddedSeconds.seconds-a.timeaddedSeconds.seconds))
        }catch(error)
        {
            console.log(error.message)
        }
    }

    useEffect(() => {
        fetchReq()
    }, [])

    const data = {
        columns: [

            {
                label: 'Email ',
                field: 'idUser',
                width: 150,
            },
            {
                label: 'Actions',
                field: 'action',
                width: 300,
            },
            {
                label: 'Time',
                field: 'timeadded',
                width: 300,
            }

        ],
        rows: latestR
    }

    return (
        <MDBDataTableV5 hover
        entriesOptions={[5, 20, 25]}
        entries={5}
        pagesAmount={4}
        data={data}
        searchTop
        searchBottom={false}
        barReverse
        className="dataTableStyle"
        fullPagination />
    )
}

export default LatestReq
