import React, { useEffect, useState } from 'react'
import { db } from '../firebase';
import { collection, getDocs, where, query } from 'firebase/firestore';

import { MDBDataTableV5 } from 'mdbreact';
import { Button,Card } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';

function AllUserOrders() {
    const [orders, setorders] = useState([])
    const [rowsOr, setrowsOr] = useState([])

    const { currentUser } = useAuth();





    const ArchiveItems = (props) => {
        return (
            <Card className=" container-sm bg-dark text-white m-4 ArchiveItemsStyle" style={{ width: '25rem' }}>
                <Card.Body>
                    <Card.Title className="cardTitle">{props.id}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted cardTitle">{props.date}</Card.Subtitle>
                    <Card.Text>
                        <label>Ville</label>
                        <h4>{props.location}</h4>
                        <label>Type</label>
                        <h4>{props.type}</h4>
                    </Card.Text>
                </Card.Body>
            </Card>)
    }
    const accepteOrder = (id, time) => {
        console.log(id + " " + time)
    }
    const fetchOrder = async () => {
        try {
            const ref = collection(db, "Orders")
            const q = query(ref, where("idUser", "==", currentUser.email), where("archived", "==", "true"))
            const row = []
            const querySnapshot = await getDocs(q)
            querySnapshot.docs.map(((doc) =>
                row.push({
                    id: doc.id,
                    idUser: doc.data().idUser,
                    location: doc.data().location,
                    timeadded: doc.data().timeadded.toDate().toDateString(),
                    type: doc.data().type
                })
            ))
            setorders(row)



        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        fetchOrder()
    }, [])

    const data = {
        columns: [

            {
                label: 'Ville',
                field: 'location',
                width: 200,
            },
            {
                label: 'Quantite',
                field: 'qtt',
                width: 50,
            },
            {
                label: 'Type',
                field: 'type',
                width: 100,
            },
            {
                label: 'Date de commande',
                field: 'timeadded',
                width: 200,
            },

        ],
        rows: orders
    }



    return (
        <div className="container">
            <h1 className="" style={{textAlign:"center"}}>Archive</h1>
            {orders.map((order)=>
                <ArchiveItems id={order.id} date={order.timeadded} type={order.type} location={order.location}  />
                )
            }
            {/* <MDBDataTableV5 hover
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                data={data}
                searchTop
                theadTextWhite
                tbodyTextWhite
                searchBottom={false}
                barReverse
                className="dataTableStyle bg-dark text-info m-4 rounded"
                fullPagination /> */}

        </div>
    )
}

export default AllUserOrders
