import React, { useContext, useState, useEffect } from "react"
import { auth } from "../firebase"
import firebase from "firebase/compat/app";

import { db } from "../firebase"
import { getDoc, doc } from 'firebase/firestore';

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [isRoleAdmin, setIsRoleAdmin] = useState()
  const [isRoleDelivery, setIsRoleDelivery] = useState()
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  // function updatePassword(password) {
  //   return currentUser.updatePassword(password)
  // }
  function updatePassword(password,newpass){
    console.log(currentUser.email)
    const crd=firebase.auth.EmailAuthProvider.credential(currentUser.email, password);
   return currentUser.reauthenticateWithCredential(crd).then(()=>{
      currentUser.updatePassword(newpass)});
  }



   const isAdmin =async(currentUser)=> {
     if(currentUser){
      try {
        const docRef = doc(db, "Users", currentUser.email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          if (docSnap.data().role === "admin") {
            console.log('is admin')
            setIsRoleAdmin(currentUser)
          }
          else if(docSnap.data().role === "delivery"){
            console.log('is delivery')
            setIsRoleDelivery(currentUser)
          }
          else {
            console.log("is user");
            //setIsRoleAdmin()
          }
        } else {
          console.log("email not found!");
        }
      } catch (error) {
        console.log(error.message)
      }
    } else console.log("no user")
    
  }
  //--- useEffect

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      isAdmin(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser, isRoleAdmin,isRoleDelivery,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}