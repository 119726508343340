import React from 'react'
import image1 from '../img/fastcoursierservice.png'
import { HeaderMenu2 } from '../Header/HeaderMenu2'

function ServiceFastcoursier() {
    return (
        <div>
            <HeaderMenu2 />
            <div className="container" id="slide">
            <img src={image1} alt="" width="90%" height="600" />
                <div id="post-831" className="post-wrap post-831 post type-post status-publish format-standard has-post-thumbnail hentry category-service-de-coursier">
                    <div className="article-wrap flex-box">
                        <div className="post-details">
                            <div className="post-detail">
                                <h2 className="has-vivid-cyan-blue-color has-text-color"><strong>Des services de coursier multiformes</strong></h2>
                                <h3 className="has-vivid-red-color has-text-color"><strong>Pour les professionnels</strong></h3>
                                <p><strong>Fast<span>&nbsp;</span>Coursier</strong><span>&nbsp;</span>r&eacute;pond &agrave; tous vos besoins de livraison de colis, plis ferm&eacute;s, documents administratifs et autres besoins de<span>&nbsp;</span><strong>livraison d&rsquo;entreprise &agrave; entreprise</strong>. Un coup de fil ou une demande via notre messagerie, et nous sommes &agrave; votre service.</p>
                                <strong>Notre solution pour votre e-commerce</strong>
                                <p>Pour tous vos services de e-commerce,<span>&nbsp;</span><strong>Fast<span>&nbsp;</span></strong><strong>Coursier</strong><span>&nbsp;</span>est votre partenaire de choix. En effet, nous avons compris que derri&egrave;re votre boutique virtuelle, il n&rsquo;est pas toujours &eacute;vident de r&eacute;pondre physiquement &agrave; une commande. Alors,<span>&nbsp;</span><strong>Fast<span>&nbsp;</span></strong><strong>Coursier</strong><span>&nbsp;</span>vole au secours des artisans en ligne et des boutiques virtuelles de vente de tout type d&rsquo;articles. Nous proc&eacute;dons aux<span>&nbsp;</span><strong>livraisons des commandes</strong><span>&nbsp;</span>faites en ligne par vos clients dans le respect des d&eacute;lais.</p>
                                <h3 className="has-vivid-red-color has-text-color"><strong>Location de coursier</strong></h3>
                                <p>Nous vous proposons la solution de transport sur mesure, qui vous permet d&rsquo;avoir un<span>&nbsp;</span>coursier<span>&nbsp;</span>v&eacute;hicul&eacute; &agrave; disposition pour la dur&eacute;e de votre choix.</p>
                                <p>Ainsi, vous pouvez vous d&eacute;tendre lors de vos courses en ville et prendre tout le temps qu&rsquo;il vous faudra pour atteindre vos objectifs. Pendant que<span>&nbsp;</span><strong>Fast<span>&nbsp;</span></strong><strong>Coursier</strong><span>&nbsp;</span>assure en arri&egrave;re-plan la r&eacute;ussite de vos courses, contentez-vous d&rsquo;&ecirc;tre pleinement satisfait.</p>
                                <h3 className="has-vivid-red-color has-text-color"><strong>Pour les particuliers</strong></h3>
                                <p>Notre &eacute;quipe jeune et dynamique met &agrave; votre disposition tout son savoir-faire pour r&eacute;pondre &agrave; vos besoins de<span>&nbsp;</span><strong>livraison de vos courses</strong>, plis, colis, surprises et documents administratifs. Une ordonnance &agrave; r&eacute;cup&eacute;rer, un repas &agrave; vous faire livrer le soir pour une r&eacute;ception &mdash;surprise&thinsp;? Plus la peine de stresser,<span>&nbsp;</span><strong>Fast<span>&nbsp;</span></strong><strong>Coursier</strong><span>&nbsp;</span>se charge de tout.</p>
                                <h4 className="has-vivid-green-cyan-color has-text-color">Des horaires flexibles</h4>
                                <p>Chez<span>&nbsp;</span><strong>Fast Coursier</strong>, nous sommes disponibles pour vous &agrave; toute heure du jour et de la nuit, 24&nbsp;h/24 et 7jours/7. Parce que la vie est faite d&rsquo;impr&eacute;vus, et que les soucis ne pr&eacute;occupent pas de nos horaires, nous avons r&eacute;pondu &agrave; vos besoins de<span>&nbsp;</span><strong>service de livraison</strong><span>&nbsp;</span>&agrave; tout moment.</p>
                                <p>Il suffit d&rsquo;un coup de fil au standard de Fast Coursier ou d&rsquo;un mail pour qu&rsquo;une &eacute;quipe soit d&eacute;ploy&eacute;e imm&eacute;diatement pour vous servir.</p>
                                <p>Respirez,<span>&nbsp;</span>Fast Coursier<span>&nbsp;</span>g&egrave;re&thinsp;!</p>
                                <h4 className="has-vivid-green-cyan-color has-text-color">Positionnement g&eacute;ographique</h4>
                                <p>Actuellement &agrave; Casablanca,<span>&nbsp;</span>Fast Coursier<span>&nbsp;</span>ambitionne de vous apporter ses services d&rsquo;excellence &agrave; Agadir et bient&ocirc;t dans toutes les grandes villes et m&ecirc;me les p&eacute;riph&eacute;ries du Maroc. Soyez assur&eacute;s que combler vos attentes au quotidien est la raison d&rsquo;&ecirc;tre de notre entreprise.</p>
                                <h4 className="has-vivid-green-cyan-color has-text-color">Des moyens de transport adapt&eacute;s</h4>
                                <p>Vous n&rsquo;&ecirc;tes pas sur une voie principale de la ville et vous d&eacute;sirez recevoir votre m&eacute;dicament en urgence&thinsp;? Les bouchons de la circulation urbaine vous font craindre pour la ponctualit&eacute; de la livraison de votre colis&thinsp;? vous n&rsquo;avez plus rien &agrave; craindre.<span>&nbsp;</span><strong>Fast Coursier</strong><span>&nbsp;</span>vous livre &agrave; temps et quel que soit votre quartier &agrave; Casa, gr&acirc;ce &agrave; nos moyens de transport divers (motos, voitures) et notre &eacute;quipe jeune et dynamique d&eacute;vou&eacute;e &agrave; votre service.</p>
                                <h4 className="has-vivid-green-cyan-color has-text-color">La tra&ccedil;abilit&eacute; de nos transports, un gage de s&eacute;curisation de vos courses</h4>
                                <p><strong>Fast Coursier</strong><span>&nbsp;</span>met &agrave; votre disposition des<span>&nbsp;</span><strong>services de transport des courses</strong>, colis, plis et documents administratifs en toute s&eacute;curit&eacute;.</p>
                                <p>En effet, gr&acirc;ce &agrave; notre syst&egrave;me de tracking informatis&eacute; et personnalis&eacute; pour chaque course, nous pouvons vous informer en temps et en heure de la g&eacute;olocalisation de votre colis. Fini les courses perdues ou les retards de livraison de vos colis injustifiables.<span>&nbsp;</span><strong>Fast<span>&nbsp;</span></strong><strong>Coursier</strong><span>&nbsp;</span>met un point d&rsquo;honneur &agrave; vous tranquilliser.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceFastcoursier
