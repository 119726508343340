import React, { useRef, useState } from 'react'
import packages from '../img/package.png'
import document from '../img/document.png'
import {db} from '../firebase';
import { collection, addDoc,serverTimestamp, doc,setDoc} from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext'


function NewOrder() {
    const [qtt, setqtt] = useState(1)
    const [location, setlocation] = useState('Casablanca')
    const [goTo, setgoTo] = useState('Casablanca')
    const [type, settype] = useState()
    const { currentUser } = useAuth();
    const [loading, setloading] = useState(false)
    const [message, setmessage] = useState()
    const refTitle = useRef()
    const refDesc = useRef()

    const cities =[
        "Tanger","Tetouan","Casablanca","Rabat","Eljadida","Marrakesh","Safi","Agadir"
    ]

    const sendPackage = async() => {
        
        if (type == null){
            alert("choose type")
            return
        }
        setmessage("")
        setloading(true)
        let dateSeconds=Math.floor(Date.now()/1000);
        console.log(dateSeconds)
        console.log()
        try {
            const docRef = await setDoc(doc(db, "Orders",dateSeconds.toString()), {
            idUser:currentUser.email,
            nameUser:currentUser.displayName,
            location:location,
            title:refTitle.current.value,
            type: type,
            description:refDesc.current.value,
            accepted:"no",
            onDelivery:"no",
            delivered:"no",
            timeadded:serverTimestamp()

        });
        const docRef2 = await addDoc(collection(db, "latestReq"), {
            idUser:currentUser.email,
            action:`Nouveau ${type} ajoutee`,
            timeadded:serverTimestamp(),

        });
        console.log("added success")
        setloading(false)
        setmessage("Ajout terminer")

        } catch (error) {
            console.log(error.message)
        }

   
    }

    return (
        <div className="container newOrder ">
            <div className="d-block  p-3">
                <label>Titre</label>
               <input type="text" placeholder="Titre de course" ref={refTitle} className="form-control inputStyle" required/>
            </div>
            <div className="d-block  p-3">
                <label>Ville</label>
                <select className="form-select inputStyle" value={location} onChange={(e) => setlocation(e.target.value)} aria-label="Default select example" required>
                {cities.map((city)=>
                    <option selected value={city}>{city}</option>)}
                </select>
            </div>
            <div className="d-block p-3">
                <label>Description</label>
                <textarea placeholder="Nom, adresse ou numero de telephone de destinataire ..." rows="3" className="form-control inputStyle" ref={refDesc} required></textarea>
            </div>
            <label>Type de package</label>
            <div className="d-block p-3">
                

                <div className="row">
                    <div className='col text-center'>
                        <input type="radio" name="imgbackground" id="img1" className="d-none imgbgchk" value="" onChange={() => settype("Doc")} />
                        <label for="img1">
                            <img src={document} alt="Image 1" />
                            <div className="tick_container">
                                <div className="tick"><i className="fas fa-check fa-3x"></i></div>
                            </div>
                        </label>
                    </div>
                    <div className='col text-center'>
                        <input type="radio" name="imgbackground" id="img2" class="d-none imgbgchk" value="" onChange={() => settype("Pack")} />
                        <label for="img2">
                            <img src={packages} alt="Image 2" />
                            <div className="tick_container">
                                <div className="tick"><i className="fas fa-check fa-3x"></i></div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div className="">
                        {loading && 
                        <div class="spinner-border text-success" role="status">
                            <span class="sr-only" >Loading...</span>
                        </div>}
                        <div className="text-success p-2">{message}</div>
                    </div>
            <div>
                <button className="btn btn-info rounded-pill px-5" onClick={sendPackage}>Nouveau commande</button>
            </div>
        </div>
    )
}

export default NewOrder
