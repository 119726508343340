import React, { useState, useEffect } from 'react'
import NewOrder from './NewOrder'
import { Modal } from 'react-bootstrap';
import './dashboard.css'
import { db } from '../firebase';
import { collection, getDocs, where, query, updateDoc, doc } from 'firebase/firestore';
import './setting.css'
import { useAuth } from '../contexts/AuthContext';



function Myorders() {
    const [showmodal, setshowmodal] = useState(false)
    const [orders, setorders] = useState([])

    const { currentUser } = useAuth();

    const archiveDoc = async (id, delivered) => {
        if (delivered == "active") {
            try {
                const ref = doc(db, "Orders", id)
                const docRef = await updateDoc(ref, {
                    archived: "true"
                });
                fetchOrder();
            } catch (error) {

            }
        }
        else {
            alert("la commande ne pas etre deliverer")
        }

    }

    const fetchOrder = async () => {
        try {
            const ref = collection(db, "Orders")
            const q = query(ref, where("idUser", "==", currentUser.email))
            const querySnapshot = await getDocs(q)
            setorders((querySnapshot.docs.map(((doc) => ({ id: doc.id, ...doc.data() })))).filter(a => a.archived != "true"));
            console.log(querySnapshot.docs.length)
        } catch (error) {
            console.log(error.message)
        }
    }
    useEffect(() => {
        fetchOrder();
    }, [])


    const OrdersItem = (props) => {
        return (

            <li className="container orderItem">
                <label className="">Numero de commande :</label>
                <i className="btn btn-danger rounded-pill fa fa-archive fa-1x p-1 archiveBtn " onClick={() => { archiveDoc(props.id, props.delivered) }}></i>
                <h5>{props.id}</h5>
                <label>Ville :</label>
                <h5>{props.location}</h5>
                <label>Type :   </label>
                <h5>{props.type}</h5>
                <TrackingOrders accepted={props.accepted} onDelivery={props.onDelivery} delivered={props.delivered} />

            </li>
        )
    }

    const TrackingOrders = (props) => {
        return (
            <div className="card card-timeline px-2 border-none">
                <ul className="bs4-order-tracking">
                    <li className="step active">
                        <div><i className="fas fa-check-circle"></i>
                        </div>
                    </li>
                    <li className={props.accepted}>
                        <div><i className="fas fa-dolly"></i></div>  
                    </li>
                    <li className={props.onDelivery}>
                        <div><i className="fas fa-truck"></i></div>  
                    </li>
                    <li className={props.delivered}>
                        <div><i className="fas fa-home"></i></div>  
                    </li>
                </ul>
            </div>
        )
    }


    return (
        <div>
            <div>
                <div className="d-flex justify-content-center align-content-center text-center p-3">
                    <h1 className="text-center "> Nouvelle course </h1>

                    <i className="fas fa-plus-circle px-2 fa-3x neworderbutton text-success" onClick={() => setshowmodal(true)} ></i>
                </div>
                <ul className="items">
                    {/* <li className="container orderItem">
                        <i className="fas fa-plus-circle p-3 fa-3x neworderbutton text-info" onClick={() => setshowmodal(true)} ></i>
                        <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/>
                    </li> */}
                    {orders.map((item) =>
                        <OrdersItem id={item.id} location={item.location}  type={item.type} accepted={item.accepted} onDelivery={item.onDelivery} delivered={item.delivered}  />)}
                </ul>
            </div>


            <div className="modalNewOrder">
                <Modal show={showmodal} onHide={() => {
                    setshowmodal(false)
                    fetchOrder()
                }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <NewOrder />
                </Modal>
            </div>
        </div>
    )
}

export default Myorders
