import React, {useState,useEffect,useRef} from 'react'
import { db } from '../firebase';
import { collection, getDocs ,setDoc,doc, query,where} from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate ,Outlet} from 'react-router-dom';
import SidebarAdmin from './sidebarAdmin'

function Admin() {


    return (
        <div style={{ display: "flex" ,height:"100vh"}}>
            <div className="sideBarUserClass " >
                <SidebarAdmin />
            </div>
            <div className="" style={{}}>
                <Outlet />
            </div>
        </div>
    )
}

export default Admin
