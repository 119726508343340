import React,{useEffect} from 'react'
import Sidebar from '../dashboard/SideBarDash'
import {Outlet} from 'react-router-dom';
import { useNavigate,useLocation } from 'react-router-dom';

function User() {
    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname=="/user") navigate("/user/dashboard")
        if (location.pathname=="/user/") navigate("/user/dashboard")    
    }, [])
    return (
        <div style={{ display: "flex" ,height:"100vh"}}>
            <div className="sideBarUserClass" style={{height:"100vh"}}>
                <Sidebar />
            </div>
            <div className="" style={{}}>
                <Outlet />
            </div>
        </div>
    )
}

export default User
