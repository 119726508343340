import React from 'react'
import image1 from '../img/comment-passe.png'
import { HeaderMenu2 } from '../Header/HeaderMenu2'


function CommentPasse() {
    return (
        <div>
            <HeaderMenu2/>
            <div className="container" id="slide">

            <img className="" src={image1} alt="" width="90%" height="600" />
            <h2><strong className="">Comment se passe notre livraison rapide au Maroc ?</strong></h2>
            <p>
                Vous êtes particulier ou professionnel et avez besoin des services de coursier pour vous livrer le plus rapidement possible un courrier. Mais l’inconvénient avec les services de livraison est que très peu sont efficaces et rapides. Vous ne savez donc plus vers quel service de coursier vous tourner afin d’être satisfait comme vous le désirez. Pourquoi ne pas faire appel à <b className="text-dark">Fast Coursier </b> ? C’est le service de coursier que nous vous recommandons si vous êtes au Maroc. Découvrons ensemble ce prestataire de service.
            </p>

            <h2>
                <strong> Fast Coursier : qui est-ce ?</strong>
            </h2>
            <p>
                Existant depuis plusieurs années et offrant ses services à plusieurs catégories de personnes, <b className="text-dark">Fast Coursier </b>  est un spécialiste de la livraison des courses urgentes. En réalité, pour tous vos besoins de livraison rapide. <b className="text-dark">FastCoursier.ma </b> est le prestataire de service qu’il vous faut.
            </p>
            <p>

                En effet, quel que soit votre besoin de livraison, qu’il s’agisse de livraison rapide de plis, de colis, de pharmacie ou de restaurants, <b className="text-dark">Fast Coursier </b>  met à votre disposition ses services de transport en voiture ou à moto sur Casa.
            </p>
            <p>

                Pour cela, la société possède des coursiers dont le professionnalisme allie sécurité et performance afin de vous garantir une exécution rapide, prompte et efficace.
            </p>
            <p>
                <h2>
                    <strong >
                        Quels services offre Fast Coursier ?
                    </strong></h2>
                Les services de <b className="text-dark">Fast Coursier </b>  sont destinés à toute catégorie de personnes. Autrement dit, les services de cette société sont destinés aussi bien aux particuliers qu’aux professionnels. Et il faut avouer qu’avec <b className="text-dark">Fast Coursier </b> , la prestation de service dépend de la catégorie de personnes que vous êtes.
            </p>
            <p>

                Ainsi, si vous êtes un particulier, <b className="text-dark">Fast Coursier </b>  répond à toutes vos demandes de livraison de documents administratifs, de plis, de courses et de colis rapide au Maroc en mettant à votre disposition une jeune équipe de coursiers dotée d’un savoir-faire exceptionnel.
            </p>
            <p>

                Quel que soit le jour de la semaine où vous faites appel à <b className="text-dark">Fast Coursier </b> , vous avez la garantie de la qualité et de la sécurité.
            </p>
            <p>

                Quant aux professionnels, <b className="text-dark">FastCoursier.ma </b>  est capable de satisfaire toutes vos demandes de transport et de livraison de vos documents administratifs, de courses urgentes, de plis, de colis rapide et même de distribution de flyers.
            </p>
            <p>

                Avec les professionnels aussi, la garantie de la qualité et de la sécurité est au rendez-vous avec <b className="text-dark">FastCoursier.ma</b>, quel que soit le jour de la semaine où vous leur faites appel.
            </p>
            <p>

                À présent, vous savez à qui faire appel pour tous vos besoins de livraison rapide au Maroc. Maintenant, ce qu’il vous reste à faire est de contacter <b className="text-dark">Fast Coursier </b>  si vous avez urgemment besoin des services d’une entreprise de coursiers qui en plus d’effectuer rapidement votre livraison, la fera en toute sécurité et à un prix qui saura vous laisser sans mot.
            </p>
            <p>

                Il ne vous suffit que de vous rendre sur le site <a href="www.fastcoursier.ma">www.fastcoursier.ma</a> pour demander un devis selon la catégorie de personne que vous êtes ou contacter pour toute information complémentaire la structure en envoyant un mail à :<b className="text-dark"> Contact@fastcoursier.ma</b>.
            </p>
            <h2>
                <b>
                    La location de coursier : un service particulier de FastCoursier
                </b></h2>
            <p>
                Chez <b className="text-dark">FastCoursier.ma </b>, vous avez accès à un service particulier dénommé  »location de coursier ». En optant pour ce service, vous avez à votre disposition pour une durée de votre choix un coursier véhiculé pour satisfaire tous vos besoins de livraison. Vous n’avez qu’à contacter la structure si cela vous intéresse.
            </p>
            </div>
        </div>
    )
}

export default CommentPasse
