import React from 'react'
import "./AboutUs.css"
function AboutUs() {
    return (
        <div className="container" id="AboutUs">
            <div className="aboutUsClass">
                <div className='itemAboutUs'>
                    <h1 className="display-4 text-center"><em><b>A propos</b></em></h1>

                    <p>
                        Vous êtes particulier ou professionnel, <b>Fast Coursier</b> est votre service de coursier dont vous avez besoin pour réaliser une livraison urgente de plis, de colis, livraison Pharmacie ou restaurants sur Casa. Nos coursiers vous assurent le plus haut niveau de performance et de sécurité pour votre livraison en moto ou en voiture.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default AboutUs
