import React, { useState, useEffect } from 'react'
import './dashboard.css'

import { db } from '../firebase';
import { collection, getDocs, limit, query, where,orderBy } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext'

function Dashboard() {

    const [latestR, setlatestR] = useState([])
    const [totalOrders, settotalOrders] = useState()
    const [ordersAccepted, setordersAccepted] = useState()
    const [onDeliveryOrders, setonDeliveryOrders] = useState()

    const { currentUser } = useAuth();
    const fetchReq = async () => {
        try {
            const ref = collection(db, "latestReq")
            const q = query(ref, where("idUser", "==", currentUser.email),limit(5))
            const row = []
            const querySnapshot = await getDocs(q)
            querySnapshot.docs.map(((doc) =>
                row.push({
                    id: doc.id,
                    idUser: doc.data().idUser,
                    action: doc.data().action,
                    timeadded: doc.data().timeadded,
                })
            ))
            setlatestR(row)
        } catch (error) {
            console.log(error.message)
        }
    }
    const fetchorders = async () => {
        try {
            const ref = collection(db, "Orders")
            const q = query(ref, where("idUser", "==", currentUser.email))
            const q2 = query(ref, where("idUser", "==", currentUser.email),where("accepted","==","active"),where("delivered","==","no"))
            const q3 = query(ref, where("idUser", "==", currentUser.email),where("delivered","==","active"))
            const querySnapshot = await getDocs(q)
            const querySnapshot2 = await getDocs(q2)
            const querySnapshot3 = await getDocs(q3)
            settotalOrders(querySnapshot.docs.length)
            setordersAccepted(querySnapshot2.docs.length)
            setonDeliveryOrders(querySnapshot3.docs.length)
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        fetchReq();
        fetchorders();
    }, [])

    const latestrequest = (item) => {
        return (<li className=" h-auto list-group-item bg-dark text-white">
            <p className="p-1 h4">{item.action} </p></li>)
    }

    function makeAdmin(){
        // admin.auth().getUserByEmail("testtes@gmail.com").then((user)=>{
        //     admin.auth().setCustomUserClaims(user.uid,{admin:true}).then(()=>
        //     {console.log(user.uid)})
        // })
    }

    return (
        <div style={{}}>

            <div className="state d-flex justify-content-lg-center align-text-center ">
                <ul className="list-group list-group-horizontal  m-3">
                    <li className="totalorder list-group-item bg-dark text-white  m-lg-3">
                        <label className="p-2">Total commandes</label>
                        <h3>{totalOrders}</h3>
                    </li>
                    <li className="deliveredorder list-group-item bg-dark text-white m-lg-3">
                        <label className="p-2">Commandes livré</label>
                        <h3>{onDeliveryOrders}</h3>
                    </li>
                    <li className="ondeliveryorder list-group-item  bg-dark text-white m-lg-3">
                        <label className="p-2">En cours</label>
                        <h3>{ordersAccepted}</h3>
                    </li>
                </ul>
            </div>
            <div className="latest ">
                <label className="ms-3 p-1 bg-dark text-white w-100 text-center rounded h4"><b>Notifications</b></label>
                <ul className="list-group ms-3 w-100 latestItem">

                    {latestR.map((item) => latestrequest(item))}

                </ul>
            </div>
        </div>
    )
}

export default Dashboard
