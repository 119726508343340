import React, { useState } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Modal } from 'react-bootstrap';
import logo from '../img/logo.png';
import './HeaderMenu.css'
import Login from '../Login/Login';
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'
import useWindowDimensions from '../Dimensions';


export const HeaderMenu2 = () => {

    const {height,width }=useWindowDimensions();
    const [showmodal, setshowmodal] = useState(false)
    let navigate = useNavigate();
    const { currentUser } = useAuth();
    
    const seconnecter = () => {
        currentUser ? navigate("/user/dashboard") : setshowmodal(true)
    }

    return (
        <>
            <Navbar bg="dark" variant="dark"
                sticky="top" expand="lg" className="Navbar" collapseOnSelect>
                <Navbar.Brand>
                    <a href="/">
                    <img src={logo} width="200" alt='' className="imgLogo" />
                    </a>
                </Navbar.Brand>


            </Navbar>
        

        </>
    )
}


